/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import PropTypes from 'prop-types';
import { CSSProperties, MouseEvent, ReactNode } from 'react';

/**
 * Modal

 * props.className - className applied to modal
 * props.style - style object applied to modal
 * props.containerStyle - style object applied to modal
 * [props.headerContainerStyles = {}] - style object applied to modal header container
 * props.header - Header text
 * props.subHeader - Subheader text
 * props.footer - footer component
 * [props.showCloseLeft] - footer component
 * [props.showCloseLeftStyles] - style object applied to component in left top corner
 * props.closeByDocument - allows closing modal by clicking document (default true)
 */

interface ModalProps {
    controlId?: string;
    containerStyle?: CSSProperties;
    style?: CSSProperties;
    showClose?: boolean;
    children: ReactNode;
    headerDivider?: boolean;
    inline?: boolean;
    footer?: ReactNode;
    className?: string;
    onCloseClick?: Function;
    closeByDocument?: boolean;
    headerStyles?: CSSProperties;
    subCloseIconMessage?: ReactNode;
    header?: ReactNode;
    subHeader?: ReactNode;
    subHeaderStyles?: CSSProperties;
    showCloseLeft?: ReactNode;
    requiredFields?: boolean;
    showCloseLeftStyles?: CSSProperties;
    subCloseIconMessageStyles?: CSSProperties;
    headerContainerStyles?: CSSProperties;
}

export default function Modal(props: ModalProps) {
    return (
        <div
            id={props.controlId}
            style={{ ...styles.modal, ...props.containerStyle }}
            onClick={e =>
                _onDocumentClick(e, {
                    controlId: props.controlId,
                    onCloseClick: props.onCloseClick,
                    closeByDocument: props.closeByDocument,
                })
            }
        >
            <div
                style={{ ...styles.modalContent, ...props.style }}
                className={props.className}
                data-test-id='modal-content'
            >
                {!props.inline && (
                    <>
                        <Header
                            headerStyles={props.headerStyles}
                            headerContainerStyles={props.headerContainerStyles}
                            showClose={props.showClose}
                            subCloseIconMessage={props.subCloseIconMessage}
                            header={props.header}
                            subHeader={props.subHeader}
                            subHeaderStyles={props.subHeaderStyles}
                            showCloseLeft={props.showCloseLeft}
                            requiredFields={props.requiredFields}
                            headerDivider={props.headerDivider}
                            showCloseLeftStyles={props.showCloseLeftStyles}
                            subCloseIconMessageStyles={
                                props.subCloseIconMessageStyles
                            }
                            onCloseClick={props.onCloseClick}
                        />
                        {props.headerDivider && <DividerLine />}
                        {props.children}
                        {props.footer && <Footer footer={props.footer} />}
                    </>
                )}
                {props.inline && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {props.showClose && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <i
                                    onClick={e => {
                                        if (props.onCloseClick) {
                                            _onCloseClick(e, {
                                                onCloseClick:
                                                    props.onCloseClick,
                                            });
                                        }
                                    }}
                                    className='fa fa-angle-times'
                                    style={{
                                        color: '#3b3b3b',
                                        cursor: 'pointer',
                                        marginRight: 10,
                                    }}
                                />
                            </div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {props.children}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

Modal.propTypes = {
    controlId: PropTypes.string,
    style: PropTypes.shape({
        modal: PropTypes.shape({
            display: PropTypes.string,
            position: PropTypes.string,
            zIndex: PropTypes.number,
            paddingTop: PropTypes.string,
            left: PropTypes.number,
            top: PropTypes.number,
            width: PropTypes.string,
            height: PropTypes.string,
            overflow: PropTypes.string,
            backgroundColor: PropTypes.string,
        }),
        modalContent: PropTypes.shape({
            position: PropTypes.string,
            backgroundColor: PropTypes.string,
            margin: PropTypes.string,
            padding: PropTypes.string,
            border: PropTypes.string,
            width: PropTypes.string,
            fontSize: PropTypes.number,
            minWidth: PropTypes.number,
            maxWidth: PropTypes.number,
            boxShadow: PropTypes.string,
            borderRadius: PropTypes.number,
        }),
    }),
    className: PropTypes.string,
    header: PropTypes.node,
    subHeader: PropTypes.string,
    showClose: PropTypes.bool,
    closeByDocument: PropTypes.bool,
    onCloseClick: PropTypes.func.isRequired,
    subCloseIconMessage: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,
    inline: PropTypes.bool,
    requiredFields: PropTypes.bool,
    headerStyles: PropTypes.shape({
        fontSize: PropTypes.number,
        fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    subHeaderStyles: PropTypes.shape({
        fontSize: PropTypes.number,
        fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    containerStyle: PropTypes.shape({}),
    headerDivider: PropTypes.bool,
    subCloseIconMessageStyles: PropTypes.shape({}),
};

Modal.defaultProps = {
    className: '',
    subHeader: '',
    controlId: 'MotiliModal',
    style: {},
    containerStyle: {},
    showClose: false,
    closeByDocument: true,
    inline: false,
    headerStyles: { fontSize: 21, fontWeight: 600 },
    subHeaderStyles: { fontSize: 16, fontWeight: 'normal' },
};

type HeaderProps = Pick<
    ModalProps,
    | 'headerStyles'
    | 'headerContainerStyles'
    | 'showClose'
    | 'subCloseIconMessage'
    | 'header'
    | 'subHeader'
    | 'subHeaderStyles'
    | 'showCloseLeft'
    | 'requiredFields'
    | 'headerDivider'
    | 'showCloseLeftStyles'
    | 'subCloseIconMessageStyles'
    | 'onCloseClick'
>;

function Header(props: HeaderProps) {
    return (
        <div>
            <div
                style={
                    props.headerDivider
                        ? { ...styles.headerContent, marginBottom: 0 }
                        : {
                              ...styles.headerContent,
                              ...props.headerContainerStyles,
                          }
                }
            >
                {props.header && (
                    <div style={{ flex: 1, ...props.headerStyles }}>
                        {props.header}
                        <div style={{ ...props.subHeaderStyles }}>
                            {props.subHeader}
                        </div>
                        {props.requiredFields && (
                            <div style={styles.requiredFieldsText}>
                                *Required Fields
                            </div>
                        )}
                    </div>
                )}
                {props.showCloseLeft && (
                    <div
                        style={{
                            ...styles.closeLeftButton,
                            ...props.showCloseLeftStyles,
                        }}
                    >
                        {props.showCloseLeft}
                    </div>
                )}
                {props.showClose && (
                    <i
                        onClick={e => {
                            if (props.onCloseClick) {
                                _onCloseClick(e, {
                                    onCloseClick: props.onCloseClick,
                                });
                            }
                        }}
                        className='fa fa-angle-times'
                        style={styles.closeButton}
                    />
                )}
                {props.subCloseIconMessage && (
                    <div
                        className='subCloseIconMessage'
                        style={{
                            ...styles.subCloseIconMessage,
                            ...props.subCloseIconMessageStyles,
                        }}
                    >
                        {props.subCloseIconMessage}
                    </div>
                )}
            </div>
        </div>
    );
}
Header.propTypes = {
    style: PropTypes.shape({
        modal: PropTypes.shape({
            display: PropTypes.string,
            position: PropTypes.string,
            zIndex: PropTypes.number,
            paddingTop: PropTypes.string,
            left: PropTypes.number,
            top: PropTypes.number,
            width: PropTypes.string,
            height: PropTypes.string,
            overflow: PropTypes.string,
            backgroundColor: PropTypes.string,
        }),
        modalContent: PropTypes.shape({
            position: PropTypes.string,
            backgroundColor: PropTypes.string,
            margin: PropTypes.string,
            padding: PropTypes.string,
            border: PropTypes.string,
            width: PropTypes.string,
            fontSize: PropTypes.number,
            minWidth: PropTypes.number,
            maxWidth: PropTypes.number,
            boxShadow: PropTypes.string,
            borderRadius: PropTypes.number,
        }),
    }),
    header: PropTypes.node,
    subHeader: PropTypes.string,
    showClose: PropTypes.bool,
    onCloseClick: PropTypes.func.isRequired,
    subCloseIconMessage: PropTypes.string,
    requiredFields: PropTypes.bool,
    headerStyles: PropTypes.shape({
        fontSize: PropTypes.number,
        fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    subHeaderStyles: PropTypes.shape({
        fontSize: PropTypes.number,
        fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    containerStyle: PropTypes.shape({}),
    headerDivider: PropTypes.bool,
    subCloseIconMessageStyles: PropTypes.shape({}),
};

function Footer(props: Pick<ModalProps, 'footer'>) {
    return <div style={{ margin: '30px 0' }}>{props.footer}</div>;
}
Footer.propTypes = { footer: PropTypes.node };

const DividerLine = () => {
    return (
        <div
            style={{
                borderBottom: '0.5px solid #818181',
                width: '100%',
            }}
        />
    );
};

function _onDocumentClick(
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    props: Pick<ModalProps, 'controlId' | 'onCloseClick' | 'closeByDocument'>
) {
    if (
        props.closeByDocument === false ||
        e.target !== document.getElementById(props.controlId || '')
    ) {
        return undefined;
    }
    if (props.onCloseClick) {
        return props.onCloseClick(e);
    }
    return undefined;
}

function _onCloseClick(
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    props: Pick<ModalProps, 'onCloseClick'>
) {
    if (props.onCloseClick) {
        return props.onCloseClick(e);
    }
    return undefined;
}

const styles: { [key: string]: CSSProperties } = {
    modal: {
        display: 'block',
        position: 'fixed' /* Stay in place */,
        zIndex: 50 /* Sit on top */,
        paddingTop: 100 /* Location of the box */,
        paddingBottom: 100,
        left: 0,
        top: 0,
        width: '100%' /* Full width */,
        height: '100%' /* Full height */,
        overflow: 'auto' /* Enable scroll if needed */,
        backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */,
    },
    modalContent: {
        position: 'relative',
        backgroundColor: 'white',
        margin: 'auto',
        padding: '50px 50px',
        border: '1px solid #888',
        width: '80%',
        fontSize: 14,
        maxWidth: 850,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 21,
        color: '#3b3b3b',
        paddingBottom: 20,
        marginBottom: 20,
    },
    closeButton: {
        position: 'absolute',
        right: 25,
        top: 15,
        fontSize: 25,
        cursor: 'pointer',
        color: 'gray',
    },
    closeLeftButton: {
        position: 'absolute',
        left: 25,
        top: 15,
    },
    subCloseIconMessage: {
        right: '17px',
        top: '60px',
        position: 'absolute',
        color: 'gray',
    },
    requiredFieldsText: {
        color: '#3f82a5',
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
};
