import { JsonSchema, UISchemaElement } from '@jsonforms/core';

export interface Identity {
    pk: string;
    sk: string;
}

export interface Auditable {
    createdAt: string;
    createdBy: {
        displayName: string;
        id: number;
    };
}

export interface FormResponse {
    forms: FormDefinition[];
    schemas: Schema[];
    uiSchemas: UiSchema[];
}

export interface Schema extends Identity, Auditable {
    schema: JsonSchema;
}

export interface UiSchema extends Identity, Auditable {
    uiSchema: UISchemaElement;
    required?: string[];
}

export interface FormDetails {
    name: string;
    description: string;
    status: string;
    version: string;
    equipmentType: any;
    clientId: number;
}

export interface FormDefinition extends Schema, FormDetails {
    ui: (FormRef | FormUiDef)[];
}

export interface RenderableForm extends FormDefinition {
    uiSchema: any;
    required?: string[];
}

export enum FormDefinitionType {
    ref = 'ref',
    uiSchema = 'uiSchema',
    schema = 'schema',
}

export interface FormRef {
    type: FormDefinitionType.ref;
    ref: Identity;
}

export interface FormUiDef {
    type: FormDefinitionType.uiSchema;
    uiSchema: UISchemaElement;
    required?: string[];
}

export interface Asset {
    id: number;
    UUID: string;
    addedByWorkOrderId: number;
    propertyId: number;
    retired: boolean;
    type: string;
    statusId: string;
    attributes: any[];
    location?: string;
    placement?: string;
    clientId: number;
    manufacturer?: string;
    manufacturerDate?: string;
    installDate?: string;
    serialNumber?: string;
    productNumber?: string;
    modelNumber?: string;
    qrCode?: string;
    qrCodeUrl?: string;
    photos: Photo[];
    inspections?: Inspection[];
    comments: {
        created: string;
        message: string;
    }[];
    recommendation?: {
        type: string;
        reasons: string[];
        reasonDetails: string;
    };
    tags: AssetTag[];
    tag: AssetTag;
    created?: string;
    updated?: string;
    // selectedAssetTypeOption: {},
    // attributes: [],
    untaggableReason: {
        display: string;
        reasonDetails: string;
    };
}

export interface Inspection {
    environmentScore: number;
    wearAndTearScore: number;
    overallConditionScore: number;
}

export interface AssetTag {
    id?: number;
    workOrderId: number;
    propertyId: number;
    UUID: string;
    retired: boolean;
    type: string;
    statusId: string;
    taggedOn: string;
    location?: string;
    placement?: string;
    clientId: number;
    manufacturer?: string;
    manufacturerDate?: string;
    installDate?: string;
    serialNumber?: string;
    productNumber?: string;
    modelNumber?: string;
    qrCode?: string;
    qrCodeUrl?: string;
    photos: Photo[];
    inspection?: {
        environmentScore: number;
        wearAndTearScore: number;
        overallConditionScore: number;
    };
    comments: {
        created: string;
        message: string;
    }[];
    recommendation?: {
        type: string;
        reasons: string[];
        reasonDetails: string;
    };
    created: string;
    updated?: string;
    attributes: {
        id: string;
        type: string;
        display: string;
    }[];
    untaggableReason: {
        display: string;
        reasonDetails: string;
    };
}

export interface AssetConfiguration {
    id?: number;
    created: string;
    typeId: string;
    key: string;
    tradeTypeId: string;
    display: string;
    active: boolean;
    categories: string[];
}

export interface Photo {
    id: string;
    assetId: number;
    photo: object;
    url: string;
    fileName?: string;
    typeId: string;
    description?: string;
    mimeType: string;
    statusId: string;
    hitId?: string;
    capturedOnId: number;
    capturedOnNumber: number;
    created: string;
}
