/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import countryToCurrency from 'country-to-currency';
import goodmanSvg from 'images/Goodman.svg';
import ecmSvg from 'images/EastCoastMetals_Icon.svg';
import fergusonSvg from 'images/Ferguson_Icon.svg';
import johnstoneSvg from 'images/Johnstone_Icon.svg';
import stevensSvg from 'images/Stevens_Icon.svg';
import williamsSvg from 'images/Williams_Icon.svg';
import thermalSvg from 'images/Thermal_Icon.svg';
import budgetAirSupplySvg from 'images/BudgetAirSupply_Icon.svg';
import aafFlandersSvg from 'images/AAFFlanders_Icon.svg';
import coscoSvg from 'images/Cosco_Icon.svg';

import {
    displayDateTime,
    displayDateTimeRange,
    displayDateTimeWithTimezone,
} from './date-time';

/**
 * Determines if the current browser supports HTML5 local storage, it is required
 * @returns {boolean}
 */
export function supportsLocalStorage() {
    return typeof Storage !== 'undefined';
}

/**
 * promiseSequence - Accepts an array of promise factory functions, executes in sequence, and returns a promise that resolves to an array of results
 * NOTE the signature is similar to Promise.all but different in that the argument array is of promise factory functions, i.e. functions that when called return a promise. Not a promise object itself.
 * @param {Function[]} promiseFactories
 * @returns {Promise<any[]>}
 */
export function promiseSequence(promiseFactories) {
    const results = arguments[1] || []; // eslint-disable-line prefer-rest-params
    if (promiseFactories.length > 0) {
        return promiseFactories[0]()
            .then(res => {
                const newArr = [].concat(results);
                newArr.push(res);
                return promiseSequence(
                    promiseFactories.splice(1, promiseFactories.length),
                    newArr
                );
            })
            .catch(err => Promise.reject(err));
    }
    return Promise.resolve(results);
}

/**
 * sortAndFilterClientRoles - return sorted filtered client roles.
 * if optional account role is passed only roles of equal or lesser privilege are returned
 * @param {object[]} roles
 * @param {string} [accountRole]
 */
export function sortAndFilterClientRoles(roles, accountRole) {
    if (!roles) return [];
    const clientRE = /^client_/;
    return roles
        .filter(r => clientRE.test(r.id))
        .filter(r => r.id !== 'client_utility')
        .filter(r => lessPrivilegedThan(r, accountRole))
        .sort(_sortRoles);
}

export function sortAndFilterOpsRoles(roles, accountRole) {
    if (!roles) return [];
    const opsRE = /^motili_/;
    return roles
        .filter(r => opsRE.test(r.id))
        .filter(r => lessPrivilegedThan(r, accountRole))
        .sort(_sortRoles);
}

export function sortAndFilterContractorRoles(roles, accountRole) {
    if (!roles) return [];
    const contractorRE = /^contractor_/;
    return roles
        .filter(r => contractorRE.test(r.id))
        .filter(r => lessPrivilegedThan(r, accountRole))
        .sort(_sortRoles);
}

export function sortClientRoles(roles) {
    if (!roles) return [];
    const clientRE = /^client_/;
    return roles
        .filter(r => clientRE.test(r.id))
        .filter(r => r.id !== 'client_utility')
        .sort(_sortRoles);
}

export function sortOpsRoles(roles) {
    if (!roles) return [];
    const opsRE = /^motili_/;
    return roles.filter(r => opsRE.test(r.id)).sort(_sortRoles);
}

export function sortContractorRoles(roles) {
    if (!roles) return [];
    const contractorRE = /^contractor_/;
    return roles.filter(r => contractorRE.test(r.id)).sort(_sortRoles);
}

export const wholeNumberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const financialFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export function formatCurrency(
    amount,
    currency = 'USD',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
) {
    const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
    });
    if (!amount) return currencyFormat.format(0);
    return currencyFormat.format(amount);
}

export const momentDate = 'MM/DD/YYYY';
export const momentDateWithTime = 'MM/DD/YYYY h:mm A';
export const momentDateWithTimeAndTimezone = 'MM/DD/YYYY h:mm A z';

export function formatDateTime(date, utc = true) {
    if (!date) return '';
    return (utc ? moment(date).utc() : moment(date)).format(momentDateWithTime);
}

export function formatDateTimeWithTimeZone(date, timezone) {
    return displayDateTimeWithTimezone(moment(date).tz(timezone || 'UTC'));
}

export function formatDateTimeAvailableTimes(availableTimes, timezone) {
    return availableTimes
        .map(t =>
            displayDateTimeRange(
                moment(t.startDateTime).tz(timezone || 'UTC'),
                t.endDateTime
                    ? moment(t.endDateTime).tz(timezone || 'UTC')
                    : moment(t.startDateTime)
                          .tz(timezone || 'UTC')
                          .add(4, 'hours')
            )
        )
        .join('\n');
}

export function formatDateWithoutTimeZone(date) {
    const dateRemovedTime =
        typeof date === 'string' && date.length ? date.split('T')[0] : date;
    return moment(dateRemovedTime).format(momentDate);
}

export function formatDate(date, utc = true) {
    if (!date) return '';
    return (utc ? moment(date).utc() : moment(date)).format(momentDate);
}

export function formatDateTimeForPicker(selectedDate, propertyTimezone) {
    const { date, hour, minute, period } = selectedDate;
    const formattedHour = moment(`${hour.value} ${period.value}`, [
        'h A',
    ]).format('HH');
    return date.hour(formattedHour).minute(minute.value).tz(propertyTimezone);
}

export function formatDateRange(begin, end, dateFormat = 'MMMM D', utc = true) {
    return utc
        ? `${moment(begin).utc().format(dateFormat)} - ${moment(end)
              .utc()
              .format(dateFormat)}`
        : `${moment(begin).format(dateFormat)} - ${moment(end).format(
              dateFormat
          )}`;
}

export function getTimePassed(dateString) {
    if (!dateString) {
        return undefined;
    }
    const startDate = moment(dateString);
    const currentDate = moment();

    if (startDate.isAfter(currentDate)) {
        return '0';
    }

    const duration = moment.duration(currentDate.diff(startDate));
    return formatDuration(duration);
}

export function getTimeRemaining(dateString) {
    if (!dateString) {
        return undefined;
    }

    const futureDate = moment(dateString);
    const currentDate = moment();

    if (futureDate.isBefore(currentDate)) {
        return '0';
    }

    const duration = moment.duration(futureDate.diff(currentDate));
    return formatDuration(duration);
}

// Get time duration in _yr _m _w _d format
function formatDuration(duration) {
    const years = duration.years();
    const months = duration.months();
    const weeks = duration.weeks();
    const days = duration.days();

    const timeComponents = [];

    if (years > 0) {
        timeComponents.push(`${years}yr`);
    }
    if (months > 0) {
        timeComponents.push(`${months}m`);
    }
    if (weeks > 0) {
        timeComponents.push(`${weeks}w`);
    }
    if (days > 0) {
        timeComponents.push(`${days}d`);
    }

    return timeComponents.join(' ');
}

export function deduceAge(creationDate) {
    if (!creationDate) return '';
    let age = moment().diff(moment(creationDate), 'years');
    if (age === 1) {
        age = `${age} year`;
    } else if (age < 1) {
        age = '< 1 year';
    } else {
        age = `${age} years`;
    }
    return age;
}

export function accountingFormat(number) {
    const _num = parseFloat(number);
    if (!_.isNaN(_num)) {
        return _num < 0
            ? `$ (${numberFormatter.format(Math.abs(_num))})`
            : `$ ${numberFormatter.format(_num)}`;
    }
    return 'N/A';
}

/**
 * tests if role is less privileged than accountRole, returns a boolean
 * @param {Object} role
 * @param {String} role.id
 * @param {String} accountRole
 * @returns {Boolean}
 */
export function lessPrivilegedThan(role, accountRole) {
    if (!accountRole) {
        return true;
    }
    const motiliRE = /^motili_/;
    const ownerRE = /_owner$/;
    const adminRE = /_admin$/;
    const managerRE = /_manager$/;
    const userRE = /_user$/;
    if (motiliRE.test(accountRole) && !motiliRE.test(role.name || role.id)) {
        return true;
    }
    if (userRE.test(accountRole)) {
        return false;
    }
    if (managerRE.test(accountRole)) {
        if (userRE.test(role.name || role.id)) {
            return true;
        }
        return false;
    }
    if (adminRE.test(accountRole)) {
        if (
            managerRE.test(role.name || role.id) ||
            userRE.test(role.name || role.id)
        ) {
            return true;
        }
        return false;
    }
    if (ownerRE.test(accountRole)) {
        return true;
    }
    return false;
}

function _sortRoles(a, b) {
    const ownerRE = /_owner$/;
    const adminRE = /_admin$/;
    const managerRE = /_manager$/;
    const userRE = /_user$/;
    if (ownerRE.test(a.id) && !ownerRE.test(b.id)) {
        return -1;
    }
    if (!ownerRE.test(a.id) && ownerRE.test(b.id)) {
        return 1;
    }
    if (ownerRE.test(a.id) && ownerRE.test(b.id)) {
        return 0;
    }
    if (adminRE.test(a.id) && !adminRE.test(b.id)) {
        return -1;
    }
    if (!adminRE.test(a.id) && adminRE.test(b.id)) {
        return 1;
    }
    if (adminRE.test(a.id) && adminRE.test(b.id)) {
        return 0;
    }
    if (managerRE.test(a.id) && !managerRE.test(b.id)) {
        return -1;
    }
    if (!managerRE.test(a.id) && managerRE.test(b.id)) {
        return 1;
    }
    if (managerRE.test(a.id) && managerRE.test(b.id)) {
        return 0;
    }
    if (userRE.test(a.id) && !userRE.test(b.id)) {
        return -1;
    }
    if (!userRE.test(a.id) && userRE.test(b.id)) {
        return 1;
    }
    if (userRE.test(a.id) && userRE.test(b.id)) {
        return 0;
    }
    return 0;
}

export function generateRandomUUID() {
    return uuidv4();
}

/**
 * deleteEmptyKeys - returns a new object with non-undefined, null, NaN, and empty string (after calling .trim()) key-value pairs excluded
 * @param {Object} obj
 * @returns {Object}
 */
export function deleteEmptyKeys(obj) {
    const _obj = {};
    Object.keys(obj).forEach(key => {
        if ({}.hasOwnProperty.call(obj, key)) {
            if (
                !_.isNil(obj[key]) &&
                !_.isNaN(obj[key]) &&
                obj[key] !== '' &&
                !(obj[key].trim && obj[key].trim() === '')
            ) {
                _obj[key] = obj[key];
            }
        }
    });

    return _obj;
}

/**
 * concatStringWithElipses - returns a new string of a specified length, concatenated with elipses
 * @param {String} string
 * @param {Number} length
 * @returns {String}
 */
export function concatStringWithElipses(string, length) {
    return string && string.length > length
        ? string.substring(0, length).concat('', '...')
        : string;
}

export function trimWhiteSpace(string) {
    return string.toString().replace(/^\s+|\s+$/g, '');
}

export function parseMessageDates(message, dates, propertyTimeZone) {
    let _message;
    Object.keys(dates).forEach(dateKey => {
        if (propertyTimeZone) {
            _message = message.replace(
                `{${dateKey}}`,
                displayDateTimeWithTimezone(
                    moment(dates[dateKey]).tz(propertyTimeZone, true)
                )
            );
        } else {
            _message = message.replace(
                `{${dateKey}}`,
                displayDateTime(moment(dates[dateKey]))
            );
        }
    });
    return _message;
}

export function getMinDateTimeForScheduleJob(propertyTimeZone) {
    const now = propertyTimeZone ? moment().tz(propertyTimeZone) : moment();
    if (now.minute() > 0) {
        return now.add(4, 'hours').minute(0).second(0).millisecond(0);
    }
    return now.add(3, 'hours').minute(0).second(0).millisecond(0);
}

export function mappedDateParse(date, propertyTimeZone) {
    if (date === null || date._isAMomentObject) {
        return date;
    }
    const timeZones = {
        akdt: '-0800',
        AKDT: '-0800',
        akst: '-0900',
        hst: '-1000',
        hdt: '-900',
    };

    /*eslint-disable */
    for (const zone in timeZones) {
        const regex = new RegExp(zone, 'i');
        if (date.toLowerCase().search(regex) !== -1) {
            const newDateString = date.replace(regex, timeZones[zone]);
            const time = propertyTimeZone
                ? moment.utc(newDateString).tz(propertyTimeZone, true)
                : moment.utc(newDateString);
            return time;
        }
    }

    return moment(date);
}

/**
 * Get the nearest half-hour time interval
 *
 * @param {string} propertyTimeZone
 * @returns {moment.Moment}
 */
export function getTimeAtNextHalfHour(propertyTimeZone) {
    const now = propertyTimeZone ? moment().tz(propertyTimeZone) : moment();
    const remainder = 30 - (now.minute() % 30);

    return moment(now).add(remainder, 'minutes');
}

export function responseError(err) {
    if (_.isString(err)) {
        return err;
    }
    return `${err.statusCode} | ${err.message}`;
}

export function unathenticatedErrorObject() {
    // TODO: change message to 'Action not authorized, please contact Motili Support for assistance'
    const errObject = new Error(
        'Unable to resolve authenticated request, no token or token has expired'
    );
    errObject.statusCode = 401;
    return responseError(errObject);
}

export function navigateUp(url, n = 1) {
    return _.chain(url).split('/').dropRight(n).join('/').value();
}

/**
 * @param  {string | undefined} phone {phone number string}
 * @return {string | undefined} {phone number string with mask}
 */
export function applyPhoneMask(phone) {
    if (!phone) {
        return;
    }
    const _phone = phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return `(${_phone[1]}) ${_phone[2]}-${_phone[3]}`;
}

/**
 *
 * @param {string} text the text to validate
 * @param {number} [minLength = 3] minimum length allow after trimmed
 * @returns {boolean}
 */
export function textLengthAtLeast(text = '', minLength = 3) {
    return _.trim(text).length >= minLength;
}

/**
 *  Only use this function if you cannot add display property to data providing string (ex. 3rd party APIs)
 *  @param str
 *  @return {string}
 */
export function toCamelCase(str) {
    if (str) {
        return str
            .toLowerCase()
            .split(' ')
            .map((word, index) => {
                if (index === 0) {
                    return word;
                }
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    }
}

/**
 *
 * @param amount
 * @return {string}
 */
export function formatFinancial(
    amount,
    currency = 'USD',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    symbolSeparate = ''
) {
    const format = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
    });
    const _num = parseFloat(amount);
    const currencySymbol = getCurrencySymbolFromCurrency(currency);
    if (!_.isNaN(_num)) {
        return _num < 0
            ? `${currencySymbol}${symbolSeparate}(${format.format(
                  Math.abs(_num)
              )})`
            : `${currencySymbol}${format.format(_num)}`;
    }
    return 'N/A';
}

/**
 *
 * @param selectedContractorId
 * @param contractors
 * @return {{state: string, message: string}|{}}
 */
export function getContractorValidation(selectedContractorId, contractors) {
    const contractor = contractors.find(c => c.id === selectedContractorId);
    if (contractor && !contractor.taxId) {
        return {
            state: 'error',
            message:
                'This contractor does not have a Tax ID in our system. Please collect and add a Tax ID to ensure we can bill correctly',
        };
    }
    return {};
}

/**
 * Remove all non-numeric characters, and return result string
 *
 * @param {string} input
 *
 * @returns {string} Sanitized output
 */
export function filterNumericValue(input) {
    return input.replace(/\D/g, '');
}

export function clean(input) {
    return input.replace(/[^\x20-\x7E]/g, '');
}

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function addMenusDivider(items) {
    if (items.length <= 1) {
        return items;
    }
    const itemsAddedDivider = [];
    for (let i = 0; i < items.length; i++) {
        itemsAddedDivider.push(items[i]);
        if (i >= 0 && i < items.length - 1) {
            itemsAddedDivider.push({ divider: true });
        }
    }
    return itemsAddedDivider;
}

export function isCheckOutTimeBeforeCheckInTime(
    checkOutTime,
    workOrderState,
    propertyTimezone
) {
    const checkInTime =
        workOrderState.checkInsV2[workOrderState.checkInsV2.length - 1]
            .checkedInAt;
    return (
        checkOutTime?.date &&
        checkOutTime?.hour.value &&
        checkOutTime?.minute.value &&
        checkOutTime?.period.value &&
        formatDateTimeForPicker(checkOutTime, propertyTimezone).isBefore(
            moment(checkInTime).tz(propertyTimezone)
        )
    );
}

export function getCurrencyFromCountry(country = 'US') {
    return countryToCurrency[country];
}

export function getCurrencySymbolFromCountry(country = 'US') {
    return getCurrencySymbolFromCurrency(getCurrencyFromCountry(country));
}

export function getCurrencySymbolFromCurrency(currency = 'USD') {
    return (0)
        .toLocaleString('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
}

export function getMilesFromKilometres(kilometres, digits = 6) {
    const km2Mile = 0.621371;
    if (isNaN(kilometres)) {
        throw new Error('Invalid Input, kilometres must be number.');
    }
    const toMiles = kilometres * km2Mile;
    return toMiles.toFixed(digits);
}

export function getKilometresFromMiles(miles, digits = 6) {
    const miles2Km = 1.609344;
    if (isNaN(miles)) {
        throw new Error('Invalid Input, miles must be number.');
    }
    const toKilo = miles * miles2Km;
    return toKilo.toFixed(digits);
}

export function getDistanceUnitFromCountry(country = 'US') {
    switch (country) {
        case 'CA':
            return 'Kilometres';
        default:
            return 'Miles';
    }
}

export function getDistanceFromCountry(distance, country = 'US', digits = 6) {
    switch (country) {
        case 'CA':
            return getKilometresFromMiles(distance, digits);
        default:
            return distance.toFixed(digits);
    }
}

export function getPostalCodeExampleFromCountry(country = 'US') {
    switch (country) {
        case 'CA':
            return 'K0A 3L0';
        default:
            return '80205';
    }
}

export function getPostalCodeLabelFromCountry(country = 'US') {
    switch (country) {
        case 'CA':
            return 'POSTAL CODES';
        default:
            return 'ZIP CODES';
    }
}

export function getProvinceLabelFromCountry(country = 'US') {
    switch (country) {
        case 'CA':
            return 'PROVINCES / TERRITORIES';
        default:
            return 'STATES';
    }
}

export function displayPhoneNumberWithCountry(
    phoneNumber,
    countries,
    phoneCountry = 'US'
) {
    if (!countries) {
        return [];
    }
    const country = countries?.find(c => c.id === phoneCountry);
    return phoneCountry && country
        ? `${country.description} (${country.countryCode}) ${phoneNumber}`
        : phoneNumber;
}

export function getPhoneCallLink(phoneNumber, phoneCountry, countries) {
    if (!phoneNumber) {
        return '';
    }
    if (!phoneCountry) {
        return phoneNumber;
    }
    const country = _.find(countries, c => c.id === phoneCountry);
    if (!country) {
        return phoneNumber;
    }
    const unMaskedPhoneNumber = phoneNumber.replace(/\D/g, '');
    return `${country.countryCode}${unMaskedPhoneNumber}`;
}

export function getCountryFromCurrency(currency = 'USD') {
    switch (currency) {
        case 'CAD':
            return 'CA';
        default:
            return 'US';
    }
}

export function getCurrencyClassName(currency, availableInCountries) {
    const country = getCountryFromCurrency(currency);
    const isAvailableInCountry = country => {
        if (_.isArray(availableInCountries)) {
            return availableInCountries.includes(country);
        }
        return availableInCountries[country];
    };
    if (country && availableInCountries && !isAvailableInCountry(country)) {
        return 'currencyUnavailable';
    }
}

export function getDistanceFromLatLonInMile(
    latitude1,
    longitude1,
    latitude2,
    longitude2
) {
    if (
        !(
            _.isFinite(latitude1) &&
            _.isFinite(longitude1) &&
            _.isFinite(latitude2) &&
            _.isFinite(longitude2)
        )
    ) {
        return NaN;
    }
    const radlat1 = (Math.PI * latitude1) / 180;
    const radlat2 = (Math.PI * latitude2) / 180;
    const theta = longitude1 - longitude2;
    const radtheta = (Math.PI * theta) / 180;
    let distance =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    distance = Math.acos(distance);
    distance = (distance * 180) / Math.PI;
    distance = distance * 60 * 1.1515;
    return distance;
}

const photoTypes = ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'ico'];
export function isSupportedPhotoFormat(fileType) {
    return photoTypes.includes(fileType && fileType.toLowerCase());
}

export function isNotApplicablePhoto(url) {
    return (
        url ===
        'https://res.cloudinary.com/motili/image/upload/v1620143124/assets/primary/motili-primary-default.png'
    );
}

export const getParamsObjectFromUrl = url => {
    if (!url) return {};
    const question = url.indexOf('?');
    let hash = url.indexOf('#');
    if (hash == -1 && question == -1) return {};
    if (hash == -1) hash = url.length;
    const query =
        question == -1 || hash == question + 1
            ? url.substring(hash)
            : url.substring(question + 1, hash);
    let result = {};
    query.split('&').forEach(function (part) {
        if (!part) return;
        part = part.split('+').join(' '); // replace every + with space, regexp-free version
        const eq = part.indexOf('=');
        let key = eq > -1 ? part.substr(0, eq) : part;
        const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
        const from = key.indexOf('[');
        if (from == -1) result[decodeURIComponent(key)] = val;
        else {
            const to = key.indexOf(']', from);
            const index = decodeURIComponent(key.substring(from + 1, to));
            key = decodeURIComponent(key.substring(0, from));
            if (!result[key]) result[key] = [];
            if (!index) result[key].push(val);
            else result[key][index] = val;
        }
    });
    return result;
};

export const getToggleGroupValueFromSelection = (term, type) => {
    if (term === undefined) {
        return undefined;
    }
    const typeToValuesMap = {
        'checked-notChecked': term ? 'checked' : 'notChecked',
        'blank-populated': term ? 'blank' : 'populated',
        'planned-unplanned': term ? 'planned' : 'unplanned',
        'occupied-unoccupied': term ? 'occupied' : 'unoccupied',
    };
    return typeToValuesMap[type];
};

export const mapToggleOptionsToRadioButtonGroup = (options, selectedValue) => {
    let _selectedValue = selectedValue?.term;
    if (!_selectedValue) {
        _selectedValue = 'none';
    }
    return options.map(option => ({
        ...option,
        checked: option.value === _selectedValue,
    }));
};

export const calculateTotalTimePerCheckIn = (checkInTime, checkOutTime) => {
    if (!checkInTime || !checkOutTime) return '0m 0d 0h 0m 0s';
    const momentCheckInTime = moment(checkInTime);
    const momentCheckOutTime = moment(checkOutTime);
    if (momentCheckOutTime.isBefore(momentCheckInTime)) return '0m 0d 0h 0m 0s';
    const duration = moment.duration(
        momentCheckOutTime.diff(momentCheckInTime)
    );
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${months}m ${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export const formatNumberWithUnit = (number, unit) => {
    return `${number} ${unit}${Number(number) === 1 ? '' : 's'}`;
};

export const getVendorLogo = vendor => {
    switch (vendor) {
        case 'Goodman':
            return goodmanSvg;
        case 'East Coast Metals':
            return ecmSvg;
        case 'Ferguson':
            return fergusonSvg;
        case 'Johnstone':
            return johnstoneSvg;
        case 'Stevens Equipment Supply':
            return stevensSvg;
        case 'Williams':
            return williamsSvg;
        case 'Cosco':
            return coscoSvg;
        case 'Budget Air Supply':
            return budgetAirSupplySvg;
        case 'AAF/Flanders':
            return aafFlandersSvg;
        case 'Thermal':
            return thermalSvg;
        default:
            return goodmanSvg;
    }
};

/**
 * Removes a phone number from its country code and formats it with area code, prefix and line no.
 *
 * @param {string} phone - The input phone number string.
 * @returns {string} A string representing the formatted phone number.
 *
 * @example
 * When the regex pattern is matched:
 * -- removePhoneNumberFromCountryCode('+1 (555) 123-4567')
 * -- Returns: '(555) 123-4567'
 *
 * -- removePhoneNumberFromCountryCode('+44 7911 123456')
 * -- Returns: '(791) 112-3456'
 *
 * When the regex pattern is not matched:
 * -- removePhoneNumberFromCountryCode('123-456-7890')
 * -- Returns: '123-456-7890'
 *
 * -- removePhoneNumberFromCountryCode('123-456-78901')
 * -- Returns: '123-456-78901'
 *
 * -- removePhoneNumberFromCountryCode('(123-456-7890')
 * -- Returns: '(123-456-7890'
 */

export const removePhoneNumberFromCountryCode = (phone = '') => {
    const pattern =
        /^(\+\d{1,3})\s?((\(\d{3}\)|\d{3}))[- ]?(\d{3})[- ]?(\d{4})$/;
    const match = phone.match(pattern);

    if (match) {
        const countryCode = match[1];
        const areaCode = match[2];
        const prefix = match[4];
        const lineNumber = match[5];
        const formattedAreaCode = areaCode.includes('(')
            ? areaCode
            : `(${areaCode})`;

        const phoneNumber = `${formattedAreaCode} ${prefix}-${lineNumber}`;
        return phoneNumber;
    }

    return phone;
};
