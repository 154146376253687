/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import { useCountriesQuery } from 'common/stores/redux/MetaStore/MetaStoreApi';
import * as Validator from '../../utils/Validator';
import FormTextInput from './FormTextInput';
import * as TwilioService from '../../services/TwilioService';
import FormSelect from './FormSelect';

import styles from './FormPhoneInput.module.scss';

const FormPhoneInput = ({
    value,
    phoneCountry,
    isMobile,
    onChange,
    saveUnmasked,
    validation,
    className,
    dataTestId,
    label,
    labelStyle,
    required,
    disabled,
    flexDirection,
    usePortal = true,
}) => {
    const { data: countries } = useCountriesQuery();
    const countryCodeOptions = countries?.map(c => ({
        id: c.id,
        display: `${c.description} (${c.countryCode})`,
    }));

    const [busy, setBusy] = useState(false);
    const [isValid, setIsValid] = useState(value ? true : null);

    useEffect(() => {
        setIsValid(null);
        validatePhone(value, phoneCountry);
    }, [value, phoneCountry, isMobile, validatePhone]);

    const onBlur = (...args) => {
        const inputValue = args[0].target.value;
        if (inputValue && !Validator.isPhone(inputValue, phoneCountry)) {
            setIsValid(false);
            if (onChange) {
                onChange({
                    value: getValue(inputValue),
                    isValid: false,
                    phoneCountry,
                });
            }
        } else {
            validatePhone(inputValue, phoneCountry);
        }
    };

    const onChangeInput = (...args) => {
        const inputValue = args[0].target.value;
        if (onChange) {
            onChange({
                value: getValue(inputValue),
                phoneCountry,
            });
        }
    };

    const onChangeCountryCode = (...args) => {
        const country = args[0].id;
        if (onChange) {
            onChange({
                value,
                phoneCountry: country,
            });
        }
    };

    const getValidationState = () => {
        if (isValid === true) {
            return { state: 'success' };
        }
        if (isValid === false) {
            let message = isMobile
                ? 'Invalid mobile format'
                : 'Invalid phone format';
            if (validation.message) {
                message = validation.message;
            }
            return { state: 'error', message };
        }
        return undefined;
    };

    const getValue = useCallback(
        inputValue => {
            return saveUnmasked ? unMask(inputValue) : inputValue;
        },
        [saveUnmasked]
    );

    const validatePhone = useCallback(
        (inputValue, country) => {
            if (inputValue) {
                const phoneValidator = saveUnmasked
                    ? Validator.isUnformattedPhone
                    : Validator.isPhone;
                if (phoneValidator(inputValue, country)) {
                    const _service = isMobile
                        ? TwilioService.validateMobile
                        : TwilioService.validatePhone;
                    setBusy(true);
                    _service(inputValue, country)
                        .then(valid => {
                            setIsValid(valid);
                            setBusy(false);
                            if (onChange) {
                                onChange({
                                    value: getValue(inputValue),
                                    isValid: valid,
                                    phoneCountry: country,
                                });
                            }
                        })
                        .catch(() => {
                            setIsValid(false);
                            setBusy(false);
                            if (onChange) {
                                onChange({
                                    value: getValue(inputValue),
                                    isValid: false,
                                    phoneCountry: country,
                                });
                            }
                        });
                }
            }
        },
        [getValue, isMobile, onChange, saveUnmasked]
    );

    return (
        <div className={className}>
            {label && (
                <ControlLabel style={labelStyle || { fontSize: 12 }}>
                    {label}
                    {required && <span className={styles.requiredStar}>*</span>}
                </ControlLabel>
            )}
            <div
                className={`${styles.flexed} ${
                    flexDirection === 'row'
                        ? styles.rowInput
                        : styles.columnInput
                }`}
                data-test-id={dataTestId}
            >
                <div className={styles.input}>
                    <FormSelect
                        options={countryCodeOptions}
                        value={phoneCountry}
                        onChange={onChangeCountryCode}
                        disabled={disabled || busy}
                        menuPortalTarget={usePortal ? document.body : null}
                        menuPosition='absolute'
                        menuShouldScrollIntoView={false}
                    />
                </div>
                <div className={styles.input}>
                    <FormTextInput
                        validation={{
                            ...getValidationState(),
                        }}
                        mask='phone'
                        onChange={onChangeInput}
                        onBlur={onBlur}
                        value={value}
                        disabled={disabled || busy}
                    />
                </div>
            </div>
        </div>
    );
};

FormPhoneInput.defaultValidation = {
    message: 'Invalid',
};

const unMask = value => {
    return value.replace(/\D/g, '');
};

FormPhoneInput.propTypes = {
    ...FormTextInput.propTypes,
    isMobile: PropTypes.bool,
    phoneCountry: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    validation: PropTypes.shape({
        message: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    flexDirection: PropTypes.string,
    usePortal: PropTypes.bool,
};

FormPhoneInput.defaultProps = {
    ...FormTextInput.defaultProps,
    isMobile: false,
    phoneCountry: 'US',
};

export default FormPhoneInput;
